import './portfolio.css';

const Portfolio = () => {
    return (<div className="portfolio-page">
        <a href="about" className="portfolio-title">
            <h1>Martingale Stochastic Process LLC</h1>
        </a>
        
        <div className="portfolio-row">
            <a href="https://www.dropfiling.com" className="portfolio-item" target="_">
                <div>DropFiling</div>
            </a>
            <div className="portfolio-item-empty"></div>
            <div className="portfolio-item-empty"></div>
        </div>
        <div className="portfolio-row">
            <div className="portfolio-item-empty"></div>
            <a href="https://www.biascoin.com" className="portfolio-item" target="_">
                <div>BiasCoin</div>
            </a>
            <div className="portfolio-item-empty"></div>
        </div>
        <div className="portfolio-row">
            <div className="portfolio-item-empty"></div>
            <div className="portfolio-item-empty"></div>
            <a href="https://www.onustask.com" className="portfolio-item" target="_">
                <div>OnusTask</div>
            </a>
        </div>
    </div>);
}

export default Portfolio;